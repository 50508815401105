// document ready 
$(function () {
    danishagro.search.init();
}); // document ready

var danishagro = danishagro || {}; // estichem

danishagro.search = danishagro.search || new function () {
    var search = this;

    // Initialize login
    this.init = function() {
        search.initializeMainLoginButton();
    };
    
    this.initializeMainLoginButton = function(){
    	$('body').on('click','#button-login',function(e){
    		e.preventDefault();
    		$(this).blur();

    		$('.Divtoggle').slideToggle('fast');

    		return false;
    	});
    };

   }(); // danishagro.login

